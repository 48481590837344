
         .nav-offset {
            min-height:100vh;
            padding-top:50px;
          }
          
          
 
              .no-dec, .no-dec:hover, .no-dec:active, .no-dec:visited, .no-dec:focus  {
              text-decoration: none;
            }
            
            .stack-tab:focus {
              outline:none;
            }
            
            
            .sidenav-container {
             height:100vh;
             position:sticky;
             top:1px;
             padding-top:50px;
             overflow-y:auto;
             background: #f6f6f6;
             min-height:auto;
             box-sizing: border-box;
           }
           
           
           .main-content-container {
             padding-top:50px;
             min-height:100vh;
           }
          
        
            pre.unstyled {    
              display: inline-block;
              padding: 0;
              margin: 0;
              font-size: inherit;
              font-family: inherit;
              line-height:inherit;
              color: inherit;
              word-break: break-all;
              word-wrap: break-word;
              background-color: transparent;
              border: none;
              border-radius: 0;
            }
            .activity-view {
              
              background:#827c7a;
              
              
            }
            
            .activity {
              margin-bottom:40px;
            }
            
          
            
            .activity hr {
              border-top:1px solid #ddd;
            }
            
            .light {font-weight: normal;}
           
           
        
          .nav-highlight a:hover, .navbar-brand:hover {
            background:#cde8ff  !important;
          }
          
          .nav-highlight.current a, .navbar-brand.current, .nav-highlight.current a:hover, .navbar-brand.current:hover  {
            color:#fff !important;
            background: #74a3c9 !important;
          }
          
          .nav-highlight.current a, .navbar-brand.current {
            color:#fff !important;
            
          }
           
           .md {
             color:#555;
           }
           
           .md ul {
             padding:0;
             margin:0 0 14px 4px;
           }
           
           .md li {
             list-style-position: inside;
           }
          .navbar { 
            border-bottom:2px solid #74a3c9 !important;
            margin-bottom:0;
          }
          
          .navbar-inverse .navbar-brand { 
            
            margin-left: 0 !important;
          }
          .main-menu-toggle {
            border-radius: 50%;
            border-color: transparent;
            margin-right: 10px;
            background: #000;
            color:#39af00;
            width:30px;
            height:30px;
            position:absolute;
            top:10px;
            left:15px;
          }
          .main-menu-toggle:focus {
            outline:none;
          }
          .navbar-brand .glyphicon { color:#39af00;font-size: 16px;}
          
          
          
          .header-search {
              width: 50%;
              float: left;
              margin-top: 8px;
              margin-left: 11%;
          }
          
          .unread-badge {
          position: relative;
          top: -10px;
          left: -8px;
          background-color: #f15600;
          }
          
          .notification-bell {
            position: relative;
    top: 3px;
          }
          
          .sub-nav{
            background-color: #74a3c9;
            padding:10px 0 ;
          }
          
          .series-container {
            padding:10px;
          }
         
           
          .text-color {
            color:#444;
          }
          
        
          
          .star-container {
            
            position:absolute;
            right:0;
            bottom:10px;
            
          }
          
          
          
        .privacy-button {
        	margin:6px 13px 0 0;
        	display:inline-block;
        }
        .privacy-button.is-private {
        	color:#e6915e;
        }
        .privacy-button.is-public {
        	color:#569939;
        }
          
         
          .stack-card-placeholder {
            height:96px;
            background: #ccc;
            display:block;
            margin-bottom:5px;
          }
          
          .card-header {
            width:100%;
            
            
          }
          
         .add-activity-button, .remove-activity-button {
           width:50px;
           height:50px;
           border-radius:50%;
           background:#569939;
           border:2px solid #FFF;
           color:#fff;
           position:absolute;
           display: block;
           top:0;
           left:0;
         }
         
         .add-activity-button:focus {
           outline:none;
         }
         
        .remove-activity-button {
          background:#e6915e;    
        }
         
         .flip-stack-button-container { 
            width: 50px;
            height: 50px;
            position:relative;
            top:-10px;
            left:-10px;
            perspective: 100px;
          }
          
          .flip-stack-button-container__wide {
            width:100px;
            height:30px;
          }
          
          .flip-stack-inner {
            width: 100%;
            height: 100%;
            position: absolute;
            transform-style: preserve-3d;
            transition: transform 0.5s;
          }
          
          .flip-stack-inner .plane {
          margin: 0;
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          
        }
        
        .flip-stack-inner.flipped .front {
          /* bugfix for browser making top object clickable when transformed on load */
          display:none;
        }
        
         .flip-stack-inner.flipped .back {
          /* bugfix for browser making top object clickable when transformed on load */
          display:block;
        }
        .flip-stack-inner .back {
          display:none;
            transform: rotateY( 180deg );
        }
        
        .flip-stack-inner.flipped {
          transform: rotateY( 180deg );
        }
        
        .share-stack-button {
          width:100%;
        }
          
          .sticky-top {
            position: sticky;
            top: 52px;
            z-index: 99;
          }
          .subtle-input, .subtle-input:focus {
            box-shadow: none;
            outline:none;
            padding:0;
            border:none;
            background: transparent;
            display:block;
            width:100%;
          }
          
          input.subtle-input { line-height: 1;}
          
          
          .lead-form input {
            
            border:none;
            width:100%;
            box-sizing: border-box;
            background: #FFF;
            border-radius:0;
            font-size:1.1em;
            height:2.6em;
            display:block;
          }
          
          .lead-search-container {
            position:relative;
          }
          
          
          .lead-form-icon {
            position: absolute;
            right:1em;
            top:0.9em;
            color:#00b0d4;
          }
          
          .lead-new-activity, .lead-button {
            height:40px; 
            border:none;
            color:#74a3c9 ;
            font-weight:bold;
          }
          
          .activity-container .card {
            height:240px;
             
          }
          
          .stack-list-container .card{
            height:190px;
             
          }
          
          .pin-activity-button, .fork-activity-button {
            height:48px;
            width:48px;
            border:1px solid #999;
            border-radius: 50%;
            align-items: center;
            display: flex;
            justify-content: center;
            color:#999 ;
            margin: 10px 5px;
            font-size: 16px;
          }
          
          .pin-activity-button.pinned {
            border:1px solid #e55c00;
            color:#e55c00 ;
          }
          
           .fork-activity-button {
            border:1px solid #529cda;
            color:#23527c ;
          }
          
          .fork-badge {
            background-color: #74a3c9;
            position: absolute;
            top: 24px;
            right:0;
          }
          
          .auth-panel {
            margin-top:100px;
            
          }
          
          .nav-button-circle {
            border-radius:50%;
            /*background:#434343;*/
            width:40px;
            height:40px;
            display: inline-block;
            text-align: center;
            color:#999;
            box-sizing:border-box;
            padding-top:10px;
            margin-top:5px;
            border:1px solid #ccc;
          }
          
           .nav-button-circle:hover,.nav-button-circle:active,.nav-button-circle:focus  {
             
             color:#999;
             background: #ccc;
             
           }
           
           .profile-item {
             
             padding-left:50px !important;
           }
           
           .top-nav-avatar {
             position:absolute;
             top:10px;
             left:5px;
           }
          
          .aside-menu-container {
           border-top:1px solid #fff;
            border-bottom:1px solid #ccc;
          }
          
          .aside-menu-container:first-of-type {
              border-top:none;
          }
          
          .aside-menu-container a {
            color:#555;
          }
          
          .aside-menu a {
            font-size:12px;
            
          }
          .aside-menu-bullet {
            font-size:10px;
            margin-right:10px;
            color:#999;
          }
          
          .home-heading {
            border-bottom: 1px solid #fff;
          }
          
          .soft-heading {
            color:#666;
          }
          
          .sub-heading {
            line-height: 1.77;
          }
          
          .profile-image {
            border-radius:50%;
            width:180px;

          }
          
          .activity-bin {
            height:100px;
            background: #ccc;
            position: relative;
          }
          
          .activity-bin .graphic {
            
            position:absolute;
            top:10px;
            left:0;
            bottom:0;
            right:0;
            
            
          }
          
            .home-banner {
             /*background: #EBEDEC url(https://images.pexels.com/photos/8763/notebook-pencil-notes-sketch.jpg?w=1260&h=750&auto=compress&cs=tinysrgb) right top no-repeat;*/
            	 
            }

            

            .top-right {
                position: absolute;
                right: 10px;
                top: 18px;
            }

            .content {
                text-align: center;
            }
            
           
           
            
           
.subtitle, h2.subtitle {	
	font-family: 'Open Sans', sans-serif;
    font-size:30px;
	line-height:1.2;
}

.feature-subtitle {
	color:white;
	font-family: 'Open Sans', sans-serif;
	padding-top:70%;
	font-size:22px;
	text-align:center;
	margin:0;
}
.lead-text {
	
	font-family: 'Open Sans', sans-serif;
    font-size:19px;
}

.line-bottom {
	border-bottom:1px solid #ccc;
}
.dashed-line-bottom {
	border-bottom:1px dashed  #ccc;
}

.bg-yellow .line-bottom, .bg-yellow.line-bottom {
  border-bottom: 1px solid #f1e4c5;
}
.has-tag {
	padding-bottom:50px;
	position:relative;
	box-sizing: content-box;
}

.bottom-tag {
	bottom:-1px;
	left:0;
	height:40px;
	width:100%;
	border-radius: 0 0 4px 4px ;
	position:absolute;
}





/* overrides */
.ui-front {
    z-index: 10000;
}


.block {display:block;}

.inline-block {display: inline-block;}

@media only screen and (max-width : 768px) {

 .m-kill {
  display:block;
  float:none;
  width:auto;
  margin-left:0;
}

   .sidenav-container {
             height:auto;
             position:static;
     
   }


}